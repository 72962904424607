<!--
 * @Author: your name
 * @Date: 2020-09-04 11:43:43
 * @LastEditTime: 2022-09-08 16:08:47
 * @LastEditors: Seven
 * @Description: In User Settings Edit
 * @FilePath: \workbench-front\src\views\workbench\order\after-sale\upgrade\index.vue
-->
<template>
  <div class="upgrade">
    <div class="form-wrap">
      <el-form
        :model="form"
        :rules="formRules"
        label-position="right"
        ref="form"
      >
        <div class="upgrade-info-wrap">
          <h2 class="sub-title">申请售后</h2>
          <el-form-item
            prop="afterSaleType"
            style="width: 282px"
          >
            <span>售后类型</span>
            <v-text-field
              hide-details="auto"
              disabled
              outlined
              :dense="true"
              :height="42"
              value="升班"
            ></v-text-field>
          </el-form-item>
          <el-form-item
            prop="oneOrMore"
            class="inline-formitem"
          >
            <div>订单类型</div>
            <check-box
              style="margin-left: -10px"
              @update:select="switchOneOrMore"
              :list="[{label: '一升一', value: '0'}, {label: '多升一', value: '1'}]"
              v-bind:select.sync="form.oneOrMore"
              ref="check"
            />
          </el-form-item>
          <!-- 售后订单 -->
          <order :form="form" :orderTable="orderTable" :orderBtnDisabled="orderBtnDisabled" :orderModalVisible.sync="orderModalVisible" @onDeleteRow="onDeleteRow"/>
          <!-- 申请原因 -->
          <cashier :form="form"/>
        </div>

        <div class="line"></div>

        <!-- 成交信息 -->
        <transaction-infomation :transactionInfo="transactionInfo"/>

        <div class="line"></div>
        <!-- 学员信息 -->
        <student-info :isCanStudy="isCanStudy" :transactionInfo="transactionInfo" @idcardBlur="idcardBlur" :phoneList="phoneList" :signUpForm="form" :disabledChangeStuMobile="disabledChangeStuMobile" @getCustomeruser="addCustomeruser" />

        <div class="line"></div>

        <!-- 商品信息 -->
        <div class="goods-info-wrap">
          <h2 class="sub-title">商品信息</h2>
          <section class="goodsInfoBox">
            <span style="font-size: 14px;color: #333">报名类型</span>
            <el-row :gutter="24" style="margin-top: 10px">
              <el-col style="width:306px">
                <el-form-item>
                <v-text-field
                  hide-details="auto"
                  disabled
                  outlined
                  :dense="true"
                  :height="42"
                  value="升班报名"
                ></v-text-field>
              </el-form-item>
              </el-col>
            </el-row>
            <el-row class="goodsRow" :gutter="12">
              <el-col :span="8">
                <el-form-item
                  prop="goodsName"
                  @focus="onChoiceGoods"
                >
                  <div class="goodsCol">
                    <v-text-field
                      v-model="form.goodsName"
                      label="选择商品"
                      hide-details="auto"
                      readonly
                      outlined
                      :dense="true"
                      :height="42"
                      @click.native="onChoiceGoods"
                    ></v-text-field>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <div>
                  <a
                    type="error"
                    v-show="currentGoods.statusOnlineSell == 2"
                    @click="previewGoods(currentGoods.goodsId)"
                    style="margin-left: 12px"
                    >预览</a
                  >
                  <a
                    type="error"
                    :disabled="!form.goodsName"
                    @click="removeChoiceGoods(true)"
                    style="margin-left: 12px; color: #F54955;"
                    >重置</a
                  >
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="12" class="goodsRow goodspecRow">
              <el-col :span="8">
                <el-form-item prop="specName" v-if="isShowGoods">
                  <div class="goodsCol">
                    <v-text-field
                      v-model="form.specName"
                      label="选择班型"
                      hide-details="auto"
                      readonly
                      outlined
                      :dense="true"
                      :height="42"
                      @click.native="onChoiceGoodsSpec"
                    ></v-text-field>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="isShowGoods">
                <div>
                  <a
                    type="error"
                    :disabled="!form.specName"
                    @click="removeChoiceSpc"
                    style="margin-left: 12px; color: #F54955;"
                    >重置</a>
                </div>
              </el-col>
              <el-col :span="24" v-if="repeat">
                <div style="color: red">学员已报名该班型，请勿重复报名</div>
              </el-col>
            </el-row>
            <template v-if="employmentShow">
              <span style="font-size: 14px;color: #333;">就业保底底金</span>
              <el-row :gutter="8" style="margin-top: 12px">
                <el-col :span="4">
                  <el-form-item prop="jobLowestSalaryUnderCollege">
                    <v-text-field
                      style="width: 100%"
                      v-model="form.jobLowestSalaryUnderCollege"
                      label="大专以下(元/月)"
                      hide-details="auto"
                      outlined
                      :dense="true"
                      :height="32"
                    >
                    </v-text-field>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item prop="jobLowestSalaryUpCollege">
                    <v-text-field
                      style="width: 100%"
                      v-model="form.jobLowestSalaryUpCollege"
                      label="大专及大专以上(元/月)"
                      hide-details="auto"
                      outlined
                      :dense="true"
                      :height="32"
                    >
                    </v-text-field>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
            <el-form-item
                prop="specTable"
                v-if="currentGoods.goodsTemplate == goodsTemplateEnum.cjzh"
              >
                <div style="color: #666">
                  <span
                    >商品价格: ¥{{
                      (goodsSpecTable[0] && goodsSpecTable[0].goodsPrice) || 0.0
                    }}</span
                  >
                </div>
              </el-form-item>
            <!-- 规格表格 -->
            <div class="spec-wrap" v-if="isShowGoods">
              <el-form-item v-if="currentGoods.goodsTemplate != goodsTemplateEnum.cjzh">
                <el-table
                  border
                  stripe
                  size="mini"
                  element-loading-text="拼命加载中"
                  :header-cell-style="{ background: '#e5f2ff' }"
                  style="width: 100%; margin-bottom: 1px"
                  header-cell-class-name="normal-table-header"
                  :data="goodsSpecTable"
                  
                >
                <template v-for="item of goodsSpecTableColumns">
                    <el-table-column :show-overflow-tooltip="true"
                      v-if="item.prop === 'goodsDiscountPrice'"
                      :prop="item.prop"
                      :key="item.prop"
                      :label="item.label"
                    >
                      <template v-slot:default="{ row }">
                        <span
                          v-if="
                            row.goodsDiscountPrice != '' &&
                            row.goodsDiscountPrice != '--' &&
                            row.goodsDiscountPrice != undefined
                          "
                        >
                          {{
                            (row.goodsPrice - row.goodsDiscountPrice).toFixed(2)
                          }}
                        </span>
                        <span v-else>0.00</span>
                      </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true"
                      v-else
                      :key="item.label"
                      :label="item.label"
                      :min-width="item.width || ''"
                      :prop="item.prop"
                    >
                    <template v-slot:default="{row}">
                      <span>{{ row[item.prop] | empty('--') }}</span>
                    </template>
                  </el-table-column>
                  </template>
                  
                </el-table>

              </el-form-item>
              <el-form-item
                prop="specTable"
                v-if="currentGoods.goodsTemplate == goodsTemplateEnum.cjzh"
              >
                <el-table
                  border
                  stripe
                  size="mini"
                  element-loading-text="拼命加载中"
                  :row-style="{ height: '48px' }"
                  :cell-style="{ padding: '0px' }"
                  :header-cell-style="{ background: '#e5f2ff' }"
                  highlight-current-row
                  style="width: 100%"
                  header-cell-class-name="normal-table-header"
                  :data="comComGoodsInfoList"
                >
                  <template v-for="item of cjComComGoodsColumns">
                    <el-table-column :show-overflow-tooltip="true"
                      :width="item.width || '120px'"
                      :key="item.label"
                      :label="item.label"
                      :prop="item.prop"
                    />
                  </template>
                </el-table>
              </el-form-item>
            </div>
            <div class="two-table-wrap" v-if="isShowGoods">
              <el-form-item
                prop="choiceClass"
                class="choiceClass"
              >
                <selcetionClass
                  :mealGroupData="mealGroupData"
                  :type="currentGoods.type"
                  :oneItem="oneItem"
                  @confirmClass="confirmClass"
                  ref="selcetionClass"
                />
              </el-form-item>
            </div>
          </section>
        </div>
        <!-- 为缩进加section -->
        <div class="line"></div>
        <!-- 收款信息 升班 -->
        <collection-info
            :discountType.sync="discountType"  
            :discountPrice.sync="discountPrice" 
            :discountInfoList="discountInfoList"
            :discountNotuse="discountNotuse"
            :receiveItemData="receiveItemData"
            :collectionInfo="collectionInfo"
            :goodsDis="goodsDis" 
            :wxTradeOrderDiscountVOList="wxTradeOrderDiscountVOList"
            :discountTip="discountTip"
            :inputDiscountPrice="inputDiscountPrice"
            :selectDiscountInfo="selectDiscountInfo"
            :shouldPay="shouldPay"/>
      </el-form>
    </div>

    <div class="bottom-btn">
      <el-button @click="doBack" size="middle" :gap="20"
        >返回</el-button
      >
      <el-button :disabled="!currentGoods.goodsId" @click="showSureData" type="primary" size="middle" :loading="submitLoading"
        >提交</el-button
      >
    </div>
    <!-- 选择原订单 -->
    <order-modal
      ref="orderModal"
      :visible="orderModalVisible"
      :aftersaleType="1"
      :orderModalFields="orderModalFields"
      :orderModalItems.sync="orderModalItems"
      :orderModalRules="orderModalRules"
      :selectMode="form.oneOrMore"
      :businessId="orderTable[0] && orderTable[0].businessId"
      :mainOrder="orderTable[0]"
      @on-cancel="onOrderModalCancel"
      @on-ok="onOrderModalOk"
    />
    <!-- 选择商品规格的组件 -->
    <goods-specs-modal
      :visible="gsVisible"
      :specTypeArr="specTypeArr"
      :specArray="specArray"
      :specCountArr="specCountArr"
      :specPriceArr="specPriceArr"
      :key="goodsSpecModalKey"
      :currentGoodsRank="currentGoodsRank"
      @on-cancel="onGsCancel"
      @onSpecModalOk="onSpecModalOk"
    />
    <!-- 选择商品的组件 -->
    <goods-modal
      type='upgrade'
      :visible.sync="goodsDrawerVisible"
      :goodsForm="goodsForm"
      :goodsFormRules="goodsFormRules"
      :goodsFormItems="goodsFormItems"
      :businessId="businessId"
      :selectGoodsType="String(selectGoodsTypeEnum.qwUpgrade)"
      :orderTable="orderTable[0]"
      @on-ok="onGoodsDrawerOk"
      :multiChoice="form.oneOrMore == '1'"
      :schoolId="ncDealSchoolId"
    />
    <!-- 提交时确认订单弹窗 -->
    <sure
      :visible="sureVisible"
      showTitle="确认升班申请"
      :sureData="sureData"
      :loading="loading"
      @on-cancel="onSureCancel"
      @on-ok="onSureOk"
    />
    <!-- 多升一 选择学员 -->
    <selUser
      :visible="selUserVisible"
      :aftersaleType="2"
      :orderModalFields="selUserFields"
      :orderModalItems="selUser"
      :orderModalRules="selUserRules"
      selectMode="1"
      @on-cancel="onSelUserCancel"
      @on-ok="onSelUserOk"
    />

    <el-dialog title="提示" :visible.sync="showDelOrder" width="30%">
      <span>删除该售后订单将清空已选升班商品信息，确认是否删除订单</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDelOrder = false">取 消</el-button>
        <el-button type="primary" @click="sureDelOrder">确 定</el-button>
      </span>
    </el-dialog>

    <HqModal
      :visible="disountsShow"
      title="不可用优惠"
      @on-cancel="disountsShow = false"
      width="480"
    >
      <div class="discount-modal" v-for="item of discountNotuse" :key="item.id">
        <div>{{ item.activityName }}</div>
        <div>优惠¥0.00~ ¥{{ item.preferentialAmount }}.00</div>
        <div>
          <div>学员不符合优惠条件</div>
          <div>{{ item.endTime }} 过期</div>
        </div>
      </div>
      <div slot="footer">
        <el-button
          size="small"
          type="primary"
          @click="disountsShow = false"
        >确认</el-button>
      </div>
    </HqModal>
  </div>
</template>

<script>
import {
  allPayMap,
  payTypeMap,
  orderStatusMap,
  afterSaleStatusMap,
  installmentTypeMap,
  aftersaleTypeEnum,
  goodsTemplateEnum,
  selectGoodsTypeEnum,
  goodTypeEnum,
} from "../../constants";
import { 
  doPromotionCheck,
  getTransferDetail,
  getTransactionInfoByOrderId,
  getCustomerData,
  getDeptId
} from "api/customer";
import { getSelectStudentInfo, getSelectLjUserInfo, doSaveLjUser } from 'api/order/change'
import { mapGetters } from "vuex";
import { accAdd, accSub } from "@/utils/mathMethod";
import validate from "@/utils/validate";
import Checker from '@/utils/Checker';
import common from '../mixins/common';

const getRandomKey = () => {
  return Math.random().toString().slice(4);
};
export default {
  name: "OrderUpgrade",
  mixins: [common],
  data() {
    const orderTableValidator = (rule, value, cb) => {
      if (!this.orderTable.length) {
        return cb(new Error("请选择订单"));
      }
      cb();
    };
    return {
      discountType: '',
      //优惠活动字段
      wxTradeOrderDiscountVOList: [],  
      discountPrice: 0,
      discountTip:"",
      discountInfoList: [],
      discountNotuse: [],
      transactionInfo:{},
      waitPayPrice: 0,
      shouldReturnPayPrice: 0,
      goodsDiscount:0,

      showTable: false,
      xlType: 1,
      //多个<template>隐藏显示控制
      ctr: {
        showCjSingleOption: false,
        showZkSingleOption: false,
        showZkMultipleOption: false,
        showCkSingleOption: false,
      },
      //获取报考省份，已经该省份可选择的院校
      zkSingle: {
        enrollingProvinceList: [],
        enrollingSchoolList: [],
        specificationPriceDataSource: [],
        zkGoodsSpecTable: [],
        courseList: [],
        form: { provinceId: "", schoolId: "" },

        zkGoodsColumns: [
          { label: "商品价格", prop: "goodsPrice" },
          { label: "会答总数", prop: "kuaidaTotal" },
          { label: "学习有效期（月）", prop: "effectiveMonth" },
          { label: "培训有效期（月）", prop: "trainEffectiveMonth" },
          { label: "复训期（月）", prop: "retrainingCount" },
          { label: "休学次数", prop: "suspendSchoolCount" },
          { label: "转校次数", prop: "shiftSchoolCount" },
        ],
      },

      zkMultiple: {
        //是否是双层次
        isDoubleProfessional: false,
        //双层次中的专科- 省
        zkEnrollingProvinceList: [],
        //双层次中的专科 - 院校
        zkEnrollingSchoolList: [],
        //双层次中的本科 - 省
        bkEnrollingProvinceList: [],
        //双层次中的本科 - 院校
        bkEnrollingSchoolList: [],
        //用于存储双层次中已经选的专科的省，院校
        selected: {
          zkProvinceId: "", //双层次下的专科省
          zkProvinceName: "",
          zkSchoolId: "", //双层次下的专科院校
          zkSchoolName: "",
          bkProvinceId: "", //双层次下的本科省
          bkProvinceName: "",
          bkSchoolId: "", // 双层次下的本科院校
          bkSchoolName: "",
        },

        enrollingProvinceList: [],
        enrollingSchoolList: [],
        specificationPriceDataSource: [],
        zkGoodsSpecTable: [],
        courseList: [],
        mealGroupData: [],
        curEnrollingMajorName: "--", //报考专业选择时的名字的拼接（可以是多个专业+名字）
        curClassInfo: "", //当前选择的班型信息
        form: {
          provinceId: "",
          schoolId: "",
          zkProvinceId: "",
          zkSchoolId: "",
          bkProvinceId: "",
          bkSchoolId: "",
        },
        zkGoodsColumns: [
          { label: "商品价格", prop: "goodsPrice" },
          { label: "会答总数", prop: "kuaidaTotal" },
          { label: "会答频次（天）", prop: "kuaidaFrequency" },
          { label: "学习有效期（月）", prop: "effectiveMonth" },
          { label: "培训有效期（月）", prop: "trainEffectiveMonth" },
          { label: "复训期（月）", prop: "retrainingCount" },
          { label: "休学次数", prop: "suspendSchoolCount" },
          { label: "转校次数", prop: "shiftSchoolCount" },
        ],
      },

      ckSingle: {
        xlType: 2,
        goodsId: "",
        goodsPrice: "", //商品价格
        enrollingGradationDatasource: [],
        enrollingGradationList: [],
        enrollingMajorList: [],
        specificationPriceDataSource: [],
        courseList: [],
        form: {
          enrollingGradationId: "",
          enrollingMajorId: "",
        },
      },

      //原来购买的商品是否是财经商品
      originGoodsIsCj: true,
      //转班时选择的商品类型
      isCj: true, //决定了 businssInt值
      zkNewGoodsPrice: 0,
      zkGoodsDiscount: 0,
      /***
       * 针对新加的学历
       */
      graduactionForm: {
        enrollingProvinceIdList: [], //报考省份名称集合
        enrollingProvinceNameList: [], //报考省份名称集合
        enrollingMajorIdList: [], //报考专业id集合
        enrollingMajorNameList: [], //报考专业名称集合
        enrollingSchoolIdList: [], //报考院校id集合
        enrollingSchoolNameList: [], //报考省份名称集合
        gradationIdList: [], //报考层次(1专科  2本科)
        enrollingTime: "", //报考时间(用于记录成考、网教报考学级)
        xlCommodityIdList: [], //学历的规格ids
      },

      previousSpecIdArr: null,
      selUserFields: {
        userId: "",
        name: "",
        mobile: "",
      },
      installmentTypeMap,
      selUserRules: {},
      selUserVisible: false, //多升一选择学员的弹窗
      selUser: [
        {
          label: "学员姓名",
          prop: "name",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
        {
          label: "学员账号",
          prop: "mobile",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
        {
          label: "学员ID",
          prop: "userId",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
      ],
      seledUser: {}, //多升一选中的学员
      sureVisible: false, //提交时确认弹出
      sureData: {},
      receiveItemData: [], //收费项目
      tmpRow: null, //临时存要删除的订单
      showDelOrder: false,
      breadcrumbs: [
        { title: "订单售后管理", path: "/aftersale/list" },
        { title: "申请售后" },
      ],
      form: {
        oneOrMore: "0",
        reason: "",
        phpneNumber: "",
        userName: "",
        // 学员真正的学习账号
        mobile: "",
        // 学员手机
        stuMobile: "",
        // 选择的商品名称
        goodsName: "",
        // 选择的商品规格名称
        specName: "",
        stuMoblie:'',
        name:'',
        idCard: '',
        jobLowestSalaryUnderCollege: '',
        jobLowestSalaryUpCollege: '',
        education: ''
      },
      specArray: [],
      formRules: {
        reason: [
          { required: true, message: '请填写申请原因', trigger: "blur" },
        ],
        goodsName: [
          { required: true, message: "此字段必填！", trigger: "change" },
        ],
        specName: [
          { required: true, message: "此字段必填！", trigger: "change" },
        ],
        originOrder: [
          {
            required: true,
            validator: orderTableValidator,
            trigger: ["click", "change"],
          },
        ],
        customerCode: [
          {
            required: true,
            message: '此字段必填！',
            trigger: ["click", "change"],
          },
        ],
        stuMoblie: [{ required: true, message: '请填写学习账号', trigger: "change" }],
        mobile: [{ required: true, message: '此字段必填！', trigger: "change" }],
        stuName: [
          { required: true, validator: validate.validateName, trigger: "blur" },
        ],
        name: [
          { required: true, message: '请输入学员姓名', trigger: "blur" },
        ],
        education: [
          { required: true, message: '请选择学员学历', trigger: "blur" },
        ],
        idCard: [{ required: true, validator: validate.idCard, trigger: "blur" }],
        jobLowestSalaryUnderCollege: [{ required: true, validator: validate.underCollege, trigger: "change" }],
        jobLowestSalaryUpCollege: [{ required: true, validator: validate.upCollege, trigger: "change" }],
      },
      orderTable: [],
      dropoutForm: {
        payType: "0",
        bank: "",
        bankAccountName: "",
        bankAccount: "",
      },
      dropOutTable: [],
      dropOutColumns: [
        { label: "商品 ID", prop: "goodsId" },
        { label: "商品名", prop: "goodsName" },
        { label: "应收金额", prop: "totalMoney" },
        { label: "已收金额", prop: "amountReceived" },
        { label: "核定扣费金额", prop: "deductionMoney" },
        { label: "核定可退款金额", prop: "refundMoney" },
        { label: "备注", prop: "remark" },
      ],
      goodsSpecTable: [],
      goodsSpecTableColumns: [
        { label: "商品价格", prop: "goodsPrice" },
        { label: "会答总数", prop: "kuaidaTotal" },
        { label: "学习有效期（月）", prop: "effectiveMonth" },
        { label: "培训有效期（月）", prop: "trainEffectiveMonth" },
        { label: "复训期（月）", prop: "retrainingCount" },
        { label: "请假次数", prop: "leaveCount" },
        { label: "转班次数", prop: "shiftClassCount" },
        { label: "转校次数", prop: "shiftSchoolCount" },
        { label: "重读次数", prop: "restudyCount" },
        { label: "休学次数", prop: "suspendSchoolCount" },
      ],
      giftTableData: [],
      giftTableColumns: [
        { label: "附属商品", prop: "goodsName" },
        { label: "赠品类型", prop: "giftType" },
      ],
      phoneCodeStatus: false,
      phoneTipMsg: "发送短信验证码",
      smsCodeTimer: null,
      // 订单弹窗显隐
      orderModalVisible: false,
      orderModalFields: {
        orderNo: "",
        goodsName: "",
        stuName: "",
        mobile: "",
        customerCode: "",
        idCard: "",
        signSchoolName: ''
      },
      orderModalItems: [
        { label: "产品线", prop: "productId", type: "select", options: [] },
        {
          label: "订单号",
          prop: "orderNo",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
        {
          label: "客户编号",
          prop: "customerCode",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
        {
          label: "报读商品",
          prop: "goodsName",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
        {
          label: "上课校区",
          prop: "signSchoolName",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
      ],
      orderModalRules: {},
      signUpForm: {
        // stuMoblie:'',
        // name:'',
        // idCard: ''
      },
      orderExtraInfo: {},
      idCardVisible: false,
      gsVisible: false,
      // 商品规格数组
      specList: [],
      // 商品规格价格数组
      specPriceList: [],
      goodsSpecModalKey: getRandomKey(),
      // --------------商品弹窗区------------------
      goodsDrawerVisible: false,
      goodsForm: {
        productId: "",
        goodsTypeId: "",
        goodsTypeName: "",
        goodsCategoryName: "",
        goodsCategoryId: "",
        goodsRank: "",
        goodsId: "",
        goodsName: "",
      },
      currentGoods: {},
      goodsFormRules: {},
      goodsFormItems: [
        {
          type: "select",
          label: "产品线",
          prop: "productId",
          options: [],
        },
        {
          type: "search",
          label: "商品分类",
          prop: "goodsCategoryName",
          placeholder: "请选择商品分类",
        },
      ],

      
      businessId: "1",
      selectSpecIds: [],
      // 套餐分组信息
      mealGroupData: [],
      oneItem: [], //单品的信息
      // 短信验证码剩余时间
      smsCodeInterval: 60,
      collectionInfo: {
        // 选择的新升班商品售价
        newGoodsPrice: "0",
        // 原商品的售价->总已收金额
        oldGoodsPrice: "0",
        // 升班折扣 ，老师输入的
        discountPrice: 0,
        discountNewPrice: 0,
        // 是否使用余额
        isBalancePay: "0",
        // 学员的账户上的余额
        stuAccountMoney: "0",
        // 余额抵扣的价格
        balancePay: 0,
        // 余额抵扣的账户
        userMobile: "",

        userName: "", //余额账户姓名
        isLoan: "0",
        // 短信验证码
        phoneCode: "",
        //最后计算出的价格
        finalPrice: "0",
        goodsDiscountPrice: 0, //优惠价格
      },
      // 选取的原订单id数组
      orderIds: [],
      specTypeArr: [],
      specPriceArr: [],
      currentGoodsRank: 0,
      allPayMap,
      payTypeMap,
      orderStatusMap,
      afterSaleStatusMap,
      // 网校的归属老师->预约校区老师
      dealInfo: {},
      specCountArr: [],
      teachOrder: {},
      afterSaleId: this.$route.query.afterSaleId,
      userSelectMealGroup: [],
      hasStuInfo: false, //是否有在蓝鲸内部查到学生信息，查到就更新，没查到就保存 true是更新
      loading: false,
      useDraft: true, //是否使用了草稿的内容，草稿内容只用一次，防止getDetailData一直走草稿if
      specificationPriceArr: [], //规格相关信息
      isShowGoods: false,
      // 商品信息-组合套餐班型表格字段
      cjComComGoodsColumns: [
        { label: "班型", prop: "goodsName", width: "260px" },
        { label: "会答总数", prop: "kuaidaTotal", width: "140px" },
        { label: "学习有效期（月）", prop: "effectiveMonth", width: "140px" },
        {
          label: "培训有效期（月）",
          prop: "trainEffectiveMonth",
          width: "140px",
        },
        { label: "复训期（月）", prop: "retrainingCount", width: "140px" },
        { label: "请假次数", prop: "leaveCount", width: "140px" },
        { label: "转班次数", prop: "shiftClassCount", width: "140px" },
        { label: "转校次数", prop: "shiftSchoolCount", width: "140px" },
        { label: "重读次数", prop: "restudyCount", width: "140px" },
        { label: "休学次数", prop: "suspendSchoolCount", width: "140px" },
      ],
      comComGoodsInfoList: [],
      cjComComGoodsInfo: {},
      goodsTemplateEnum,
      disabledChangeStuMobile: false,//禁止修改学员信息-学员帐号
      selectGoodsTypeEnum,
      submitLoading: false,
      // 优惠劵新增4个字段
      activedParams: {},
      marketInoutConfigSnapshotId: "",
      inoutConfigSnapshot: null,
      // 是否显示就业协议
      employmentShow: false,
      disountsShow: false,
      phoneList: [],
      // 报名商品是否重复 
      repeat: false,
      // 商品定价状态 0 待定 1 已定
      statusPrice: "",
      // 是否可以修改学习账号
      isCanStudy: false,
      property: null
    };
  },
  watch: {
    "collectionInfo.balancePay"(newVal, oldVal) {
      // console.log("新旧值", newVal, oldVal);
      newVal = newVal.toString();
      if (newVal && newVal.indexOf(" ") != -1) {
        this.collectionInfo.balancePay = oldVal;
        return;
      }

      var pointIndex = newVal.indexOf(".");
      if (pointIndex > 0 && newVal.length - pointIndex > 3) {
        this.collectionInfo.balancePay = oldVal;
        return;
      }
    },
    "collectionInfo.userMobile"(newVal) {
      if (newVal.length < 11) {
        this.collectionInfo.userName = "";
        this.collectionInfo.stuAccountMoney = 0;
        this.collectionInfo.balancePay = 0;
        if (newVal.length != 0) {
          this.calcFinalMoney();
        }
      }
    },
  },
  components: {
    orderModal: () => import("../../components/order-modal.vue"),
    goodsSpecsModal: () => import("../../components/goods-specs-modal1.vue"),
    goodsModal: () => import("../../components/goods-modal1.vue"),
    sure: () => import("../../components/sure1.vue"),
    selUser: () => import("../../components/sel-user.vue"),
    selcetionClass: () => import("../../sign-up/components/selcetionClass.vue"),
    checkBox: () => import("@/components/check-box.vue"),
    order: () => import("./components/order.vue"),
    cashier: () => import("./components/cashier.vue"),
    transactionInfomation: () => import("./components/transactionInfomation.vue"),
    studentInfo: () => import("./components/studentInfo.vue"),
    collectionInfo: () => import("./components/collectionInfo.vue"),
  },
  methods: {

      /***
   * 获取优惠券列表
   */
  async startActivityDetail(specArr){
    /***
       * 获取班型的信息，
       * (0) 默认班型
       * (1) 单层单选   [{ specificationNameId1:'',  specificationName1: '' }]
       * (2) 单层多选   [{ specificationNameId1:'',  specificationName1: '' },
       *                { specificationNameId1:'',  specificationName1: '' }, ...]
       * (3) 双层单选   [{ specificationNameId1:'',  specificationName1: '', specificationNameId2:'',  specificationName2: '' }]
       * (4) 双层多选   [{ specificationNameId1:'',  specificationName1: '', specificationNameId2:'',  specificationName2: '' },
       *                { specificationNameId1:'',  specificationName1: '', specificationNameId2:'',  specificationName2: '' }]
       */
      let specificationNameId1 = '';
      let specificationNameIds2 = []
      if( specArr.length > 1 ){
         //多选，可能是(2)(4)情形 
         specArr.forEach( item=>{
           let specificationNameId1_temp = item.specificationNameId1;
           let specificationNameId2 = item.specificationNameId2;
           //表示是单层多选情形
           if(!specificationNameId2){
             specificationNameId1 = this.specTypeArr[0].id;
             specificationNameIds2.push(specificationNameId1_temp)
           }else {
             //表示双层多选，此时的specificationNameId1都是同一个
             specificationNameId1 = item.specificationNameId1;
             specificationNameIds2.push(specificationNameId2)
           }
         } )
      }else if( specArr.length == 1 ){
        //单项，则只可能是 (1)(3) 情形
        specificationNameId1 = specArr[0].specificationNameId1;
        let specificationNameId2 = specArr[0].specificationNameId2;
        if (specificationNameId2) {
          specificationNameIds2.push(specificationNameId2);
        }else {
          specificationNameIds2.push(specificationNameId1);
          specificationNameId1= this.specTypeArr[0].id;
        }
      }

      //goodsId, schoolId, specificationNameId1, specificationNameIds2
      let goodsId = this.currentGoods.goodsId
      let schoolId = this.transactionInfo.ncCampusSchoolId
      this.findActivityDetail( goodsId, schoolId, specificationNameId1, specificationNameIds2);
      this.findActivityDetail_notInUserGroup( goodsId, schoolId, specificationNameId1, specificationNameIds2);
  },

     /***
   * 获取优惠券列表
   */
  async findActivityDetail( goodsId, schoolId, specificationNameId1, specificationNameIds2 ){

    let formData = {
      goodsId,
      schoolId,
      specificationNameId1,
      specificationNameIds2,
      specificationPriceIds: this.isCj ? this.specIdArr : [...this.commodityIdList],
      userId: this.signUpForm.userId,
    };
    this.activedParams = formData
    try {
        let res = await this.$fetch("findActivityDetail",formData);
    if( res.code == 200 ){
      let data = res.data || [];
      let dataList = data.map( (item)=>{
        return {
          activityName: item.activityName,
          isMultiple: item.isMultiple,
          preferentialAmount: item.preferentialAmount,
          endTime: item.endTime,
          id: item.activityId,
          activityId: item.activityId,
          marketInoutConfigSnapshotId: item.inoutConfigSnapshot ? item.inoutConfigSnapshot.id : "",
          inoutConfigSnapshot: item.inoutConfigSnapshot
        }
      })
       this.discountInfoList = dataList;
      }
    } catch ( e ){
      console.log(e);
    }

  },
  /***
     * 获取不可用优惠券列表
     */
    async findActivityDetail_notInUserGroup(
      goodsId,
      schoolId,
      specificationNameId1,
      specificationNameIds2
    ) {
      let formData = {
        goodsId,
        schoolId,
        specificationNameId1,
        specificationNameIds2,
        specificationPriceIds: this.isCj ? this.specIdArr : [...this.commodityIdList],
        userId: this.signUpForm.userId,
      };
      try {
        let res = await this.$fetch("findActivityDetail_notInUserGroup", formData);
        if (res.code == 200) {
          this.discountNotuse = res.data || [];
        }
      } catch (e) {
        console.log(e);
      }
    },
    /***
     * 优惠金额判断
     */
    inputDiscountPrice(newValue) {
      //先判断是否选中商品优惠，如果没有则报错且退回
      let findItem = this.discountInfoList.find((item) => item.isSelected);
      if (!findItem) {
        this.$message.error("请先选择商品优惠");
        return;
      }

      let value = Number(newValue);
      if( isNaN(value) || newValue.startsWith("00") ){
        this.$message.error("请输入大于0的优惠金额");
        setTimeout(() => {
          this.discountPrice = "";
          this.getReceiveItem();
        });
        return;
      }


      newValue = value;
      if (newValue < 0) {
        this.$message.error("请输入大于0的优惠金额");
        setTimeout(() => {
          this.discountPrice = "";
          this.getReceiveItem();
        });
        return;
      }

      if( newValue > Number(this.newGoodsPrice) ){
         this.$message.error(`请输入不大商品价格 ${ this.newGoodsPrice }`);
         setTimeout(() => {
          this.discountPrice = "";
          this.getReceiveItem();
        });
        return;
      }

      let discount = Number(findItem.preferentialAmount);
      this.discountNewPrice = discount
      //  if (newValue > discount  ) {
      //    this.$message.error(`请输入不大于最大优惠金额${ discount }`);
      //   setTimeout(() => {
      //     this.discountPrice = "";
      //     this.getReceiveItem();
      //   });
      //   return;
      // }
      this.discountPrice = newValue;
      this.getReceiveItem();
    },
    /***
     * 选择优惠券信息
     */
    selectDiscountInfo( id ){
      if(!id){
        this.wxTradeOrderDiscountVOList = [];
        this.discountPrice = "";
        this.discountType = "";
        this.getReceiveItem();
        return;
      }
      this.discountInfoList.map(item => {
        if(item.id == id) {
          this.marketInoutConfigSnapshotId = item.marketInoutConfigSnapshotId
          this.inoutConfigSnapshot = item.inoutConfigSnapshot
        }
      })
      this.discountInfoList.forEach(item=>item.isSelected=false)
      let findItem = this.discountInfoList.find( item=>item.id==id  );
      findItem.isSelected = true;
      this.wxTradeOrderDiscountVOList = [{
        activityId: findItem.activityId,
        discount: 0,
        // 优惠新增4个字段
        ...this.activedParams,
        marketInoutConfigSnapshotId: this.marketInoutConfigSnapshotId 
      }];
      this.discountType = id;
    this.discountTip = findItem.endTime ? `可优惠0~${findItem.preferentialAmount}元 ${ this.$dayjs(findItem.endTime).format("YYYY-MM-DD HH:mm:ss")} 过期` : `可优惠0~${findItem.preferentialAmount}元 本优惠长期有效`;
    this.getReceiveItem()
  },

    //比对价格是否变动
    comparePrice(val) {
      console.log("开始比对价格", val, this.specificationPriceArr);
      //(1) 比较财经商品的价格是否变动
      //(2) 比较成考、网教的价格是否变动
      let specificationPriceArr = this.specificationPriceArr

      for (let i of specificationPriceArr) {
        for (let j of val) {
          if (
            (j.id == i.id && j.goodsPrice != i.goodsPrice) ||
            (j.id == i.id && j.goodsDiscountPrice != i.goodsDiscountPrice)
          ) {
            return true;
          }
        }
      }
      return false;
    },
    //获得商品详情判断是否价格变化
    async getGoodsDetail(specIdArr) {
      const params = {
        spPriceIdStr: specIdArr.toString(),
      };
      if (params.spPriceIdStr === "") return;
      const { data } = await this.$fetch(
        "findPriceInfoBySpPriceId",
        params
      );
      if(!this.comparePrice(data)){
        this.sureVisible = false;
        return true;
      }

      this.$hqMessageBox({
        title: '提示',
        message: "此商品价格发生变化，请重新选择该商品购买！"
      }).then( ()=>{
          //点击商品重置按钮
          this.removeChoiceGoods(null);
          this.sureVisible = false
      });
      return false
    },
    //输入时改变绑定值，改方法目前无效
    setMobileData(val) {
      console.log(val);
    },
    setSelcetionShow() {
      if (this.$refs.selcetionClass) {
        // this.$refs.selcetionClass.editLesson("edit") //使选中课程页变为可编辑状态
      }
      this.oneItem = [];
    },
    onSelUserCancel() {
      this.selUserVisible = false;
    },
    onSelUserOk(data) {
      console.log("选学员确定", data);
      data.mobile = data.userPhone;
      if (
        this.seledUser.mobile != data.mobile ||
        this.seledUser.idCard != data.idCard
      ) {
        this.setDeafultData();
        this.orderTable = [];
        this.$refs.orderModal.setDeafultData();
      }
      Object.assign(this.form, data);
      this.seledUser = data; //多升一选中学员的信息
      this.selUserVisible = false;
    },
    onSureCancel() {
      this.sureVisible = false;
    },
    async onSureOk(infoData) {
      let res = await this.getGoodsDetail(this.specIdArr);
      console.log('[检验商品价格变动] 没变化:', res)
      if (res) {
        this.collectionInfo.phoneCode = infoData.phoneCode;
        this.onSubmit();
      }
    },
    // //检查学员信息是否填写
    // checkStudentData() {
    //   // if (!this.signUpForm.stuMoblie) {
    //     if (!this.form.stuMoblie) {
    //     this.$message.warning("学员账号不能为空");
    //     return true;
    //   // } else if (!this.signUpForm.name) {
    //     } else if (!this.form.name) {
    //     this.$message.warning("学员姓名不能为空");
    //     return true;
    //   // } else if (!this.signUpForm.idCard) {
    //     } else if (!this.form.idCard) {
    //     this.$message.warning("学员身份证号不能为空");
    //     return true;
    //   }
    //   return false;
    // },
    //检查商品信息-选择课程表格数据
    checkMealGroupData() {
      let unpass = false;
      if (!this.mealGroupData.length) return unpass;
      this.mealGroupData.map((row) => {
        if (row.sellStatus) {
          if (row.selected_count < row.sellCount) {
            return (unpass = true);
          }
        }
      });
      return unpass;
    },

    //根据规格id和用户id获取商品状态
    async findOrderStatus() {
      const tmpParams = this.createAfterSaleOrder();
      console.log("参数", tmpParams);
      if(!tmpParams.userId){
        const hasUserId = await this.doLanJingData()
        if(hasUserId){
          tmpParams.userId = this.signUpForm.userId
        }
      }
      const params = {
        // userId: tmpParams.userId,
        // spPriceIdStr:
        //   tmpParams.createOrderVO.childSpecificationPriceIds ||
        //   tmpParams.createOrderVO.commodityIdList.toString(),
        userId: tmpParams.userId,
        commodityIdList: tmpParams.createOrderVO.childSpecificationPriceIds.split(',') ||
          tmpParams.createOrderVO.commodityIdList,
        businessIdInt: this.isCj?1:0,
        enrollingTime: ""
      };
      // console.log("规格id", tmpParams, params);

      // if(params.spPriceIdStr === '') return
      const { data, code, msg } = await this.$fetch(
        // "findOrderStatusByUserIdAndSpecificationId",
        "doCheckRepeatBuy",
        params
      );
      console.log("检查结果", data, code, msg);

      //purchaseStatusResultStatus购买状态(0 未购买(暂不返回数据) 1全款待支付 2已购买 3支付中 4分笔待支付)
      // if (data.purchaseStatusResultStatus === 0) {
      //   return false;
      // } else {
      //   this.$hqMessageBox( {
      //     title: '提示',
      //     message: '订单已存在，是否查看'
      //   } )   .then(() => {
      //       this.goOrderDetail(data);
      //     })
      //     .catch(() => {});
      //   return true;
      // }

      if(data.status == 0) {
        this.$message.error("学员已报名该班型，请重新选择班型");
        return true
      }
    },
    // 选择班型时候触发
    getthrid_checkCost () {
      let params = {
        goodsId: this.currentGoods.goodsId, //商品id
        businessIdInt: this.currentGoods.businessId,
        commodityIdList: this.isCj ? this.specIdArr : [...this.commodityIdList], //, //商品规格价格id集合
      }
      this.$fetch("thrid_checkCost", params).then(res => {
        this.getSure();
      }).catch(res => {
        if (res.code == 500) {
          return this.$message.error(res.msg)
        }
      })
    },
    getSure() {
      //点击提交
      this.signUpForm.stuMoblie = this.form.stuMoblie
      this.$refs.form.validate(async (valid) => {
        if(!this.form.reason)  return this.$message.error("请填写申请原因");
        if (!valid || this.repeat) return this.$message.error("表单填写有误，请检查");
        if(!this.statusPrice) return this.$message.error('商品收支项目未配置，暂不支持报名，请联系商品管理老师')
        // if(!this.teachOrder.assignedDeptId){
        //   alert("组织机构id不能为空!")
        //   return false
        // }
        // let checkRes = this.checkStudentData();
        // if (checkRes) return;

        if (
          this.collectionInfo.isBalancePay == 1 &&
          this.collectionInfo.balancePay <= 0
        ) {
          return this.$message.warning("使用余额抵扣需大于0");
        }
        if (this.checkMealGroupData()) {
          return this.$message.error("可选课程的数量未按要求勾选");
        }

        //查看所有选择课程
        if (!this.getAllSeledClass()) return;

        // let buyStatus = await this.findOrderStatus(); //根据规格id和用户id获取商品状态
        // console.log("购买状态", buyStatus);
        // if (buyStatus) {
        //   return;
        // }
        const upgradeDiscountPrice = Number(this.collectionInfo.oldGoodsPrice || 0) + Number(this.collectionInfo.balancePay || 0)
        let obj = {
          orderNo: this.orderTable[0].orderNo, //售后订单
          oldGoodsName: this.orderTable[0].goodsName, //原订单商品
          oldSpecName: this.orderTable[0].goodsSpecName, //原订单规格
          goodsName: this.form.goodsName, //售后商品
          spec: this.form.specName,
          goodsPrice: this.goodsSpecTable[0].goodsPrice, //商品价格
          oldGoodsPrice: this.collectionInfo.oldGoodsPrice, //已收金额
          mobile: this.collectionInfo.userMobile, //余额抵扣账号
          stuMobile: this.form.stuMoblie, //学员账号
          name: this.form.name, //学员姓名
          idCard: this.form.idCard,

          balancePay: Number(this.collectionInfo.balancePay), //余额抵扣
          finalPrice: +this.shouldPay, //应收金额
          isBalancePay: this.collectionInfo.isBalancePay, //是否使用学员余额
          discount: this.goodsSpecTable[0].goodsDiscountPrice, //优惠价格
          upgradeDiscountPrice,//升班优惠
          type: "upgrade",
        };
        this.sureData = obj;
        localStorage.setItem("sureData", JSON.stringify(this.sureData));
        localStorage.setItem(
          "showZkSingleOption",
          JSON.stringify(this.ctr.showZkSingleOption)
        );
        localStorage.setItem(
          "showCkSingleOption",
          JSON.stringify(this.ctr.showCkSingleOption)
        );
        localStorage.setItem(
          "showZkMultipleOption",
          JSON.stringify(this.ctr.showZkMultipleOption)
        );
        localStorage.setItem(
          "showCjSingleOption",
          JSON.stringify(this.ctr.showCjSingleOption)
        );
        localStorage.setItem(
          "isDoubleProfessional",
          JSON.stringify(this.zkMultiple.isDoubleProfessional)
        );
        console.log("升班", this.sureData);
        this.sureVisible = true;
      });
    },
    //点击提交
    showSureData() {
      this.getthrid_checkCost()
    },
    //收费项目，计算各种数量
    async getReceiveItem() {
      const params = {
        spPriceIdStr: this.specIdArr ? this.specIdArr.toString() : "",
      };
      this.waitPay();
      this.shouldReturnPay();
      if (params.spPriceIdStr === "") return;
      this.getComComReceiveItem(params);
    },
    //获取组合套餐的收支项
    async getComComReceiveItem(params, childSpecificationPriceIds= ''){
      // 选择的套餐课程
      let userSelectMealGroupIds =
        this.userSelectMealGroup.map(
          (item) => item.goodsSpecificationPriceId
        ) || [];
      const res = await this.$fetch('queryBySpStrAndGoodsTemp', {
        spStr: this.specIdArr.toString(),
        goodsTemp: this.currentGoods.goodsTemplate,
        upClassDecimal:this.collectionInfo.oldGoodsPrice,
        goodsDiscounts:this.discountPrice,
        childSpecificationPriceIds: userSelectMealGroupIds.toString(),
        inoutConfigSnapshot: this.inoutConfigSnapshot,
        newGoodsPrice: this.collectionInfo.newGoodsPrice,
        shouldPay: this.shouldPay
      })

      this.receiveItemData = res.data.inoutProjectList;
      if(res.data && res.data.moreThanFlag) { // 3578
        if (this.discountNewPrice < res.data.maxDiscountAmount) {
          this.$message.error(`请输入不大于最大优惠金额${ this.discountNewPrice }`);
        } else {
          this.$message.error(`请输入不大于收支项目优惠金额${res.data.maxDiscountAmount}，如有问题请联系管理员`)
        }
        this.discountPrice = ""
        this.getReceiveItem()
      } else if(this.discountPrice > this.discountNewPrice){
          this.$message.error(`请输入不大于最大优惠金额${ this.discountNewPrice }`);
          this.discountPrice = ""
      }
    },
    // 套餐任选课程结果
    confirmClass(isEdit){
      // 保存课程选择结果
      if(!isEdit && this.specIdArr && this.specIdArr.length){
        let childSpecificationPriceIds = ''
        if(this.getAllSeledClass()){
          console.log('[已选课程]', this.userSelectMealGroup)
          childSpecificationPriceIds = (this.userSelectMealGroup.map(
            (item) => item.goodsSpecificationPriceId
          ) || []).toString()
        }
        this.getComComReceiveItem({ spPriceIdStr: this.specIdArr.toString() }, childSpecificationPriceIds)
      }
    },

    setDeafult() {
      if (this.collectionInfo.balancePay == "") {
        this.collectionInfo.balancePay = 0;
      }
    },

    // 商品规格弹窗确定回调
    onSpecModalOk(specInfo) {
      this.removeDiscountInfo();
      console.log("选择商品规格点击确定", specInfo);
      const { totalPrice, specIdArr, specificationName, specArr } = specInfo;
      this.specIdArr = specIdArr;
      this.goodsSpecTable = this.getSpcMergeData(specArr);
      this.collectionInfo.newGoodsPrice = isNaN(+this.goodsSpecTable[0].goodsPrice) ? totalPrice : +this.goodsSpecTable[0].goodsPrice;
      this.form.specName = specificationName;


      this.startActivityDetail(specArr);


      this.clearValidate()
      if (this.currentGoods.type === 1) {
        this.getSetMealGroupInfo();
      }
      if(this.currentGoods.goodsTemplate == goodsTemplateEnum.cjzh){
        this.findComComGoodsInfo()
      }
      this.getOrderStatus();
      this.setSelcetionShow();
      this.getReceiveItem(); //查询收费项目
      this.calcFinalMoney();
      this.findGoodsCourseBySpPriceId();
      this.gsVisible = false;
      
      this.getEmploymentContract()

      if (this._priceChange) {
        this.$nextTick(() => {
          this.$bus.$emit("autoSelOnPriceChange", this._changeSel);
        });
      }
    },
    getSpcMergeData(data) {
      let obj = {
        kuaidaTotal: 0,
        kuaidaFrequency: 0,
      };
      let goodsPrice = 0;
      let goodsDiscountPrice = 0;
      data.forEach((item) => {
        if (item.goodsPrice){
          goodsPrice += isNaN(+item.goodsPrice) ? 0 : +item.goodsPrice
        }
        if (item.goodsDiscountPrice){
          goodsDiscountPrice += isNaN(+item.goodsDiscountPrice) ? 0 : +item.goodsDiscountPrice;
        }
          
        if (item.kuaidaTotal === 0 || item.kuaidaTotal === "0") {
          item.kuaidaTotal = "无上限";
        }
        if (item.kuaidaTotal == null) {
          item.kuaidaTotal = "--";
        }

        if (
          !(
            item.spPriceName == "默认" ||
            item.spPriceName == "默认规格" ||
            item.spPriceName == "默认班型"
          )
        ) {
          item.goodsName = item.goodsName + "-" + item.spPriceName;
        }
      });
      obj = Object.assign(obj, data[0]);
      obj.goodsPrice = accAdd(0, goodsPrice, 2);
      obj.goodsDiscountPrice = data[0].goodsDiscountPrice
        ? accAdd(0, goodsDiscountPrice, 2)
        : "--";
      console.log('[getSpcMergeData]goodsPrice:', goodsPrice, ' goodsDiscountPrice:', goodsDiscountPrice, obj);
      return [obj];
    },
    // 获取套餐分组信息
    async getSetMealGroupInfo() {
      if(!this.currentGoods.goodsId || !this.specIdArr.length) return
      const params = {
        goodsId: this.currentGoods.goodsId, //商品的id
        spPriceIdStr: this.specIdArr.toString(), //价格信息
      };

      const { data } = await this.$fetch("thrid_getGoodsGroupInfo", params);
      for (let i in data) {
        for (let j in data[i].goods) {
          data[i].closeStatus = true;
          data[i].goods[j].name = data[i].goods[j].name || "";
          data[i].goods[j].name = data[i].goods[j].name.replace(
            /(-默认$)|(-默认班型$)|(-默认规格$)/,
            ""
          );
          data[i].selComplete = false;
          if (data[i].sellStatus == 0) {
            data[i].goods[j].checked = true;
          } else {
            data[i].goods[j].checked = false;
          }
        }
      }
      this.mealGroupData = data;
      this.$nextTick(() => {
        this.mealGroupData.map((item) => {
          item.selected_count = 0;
          if (!item.sellStatus) {
            item.goods.map((obj) => {
              obj.disabled = true;
              obj.checked = true;
              if (this.$refs.multipleTable) {
                this.$refs.multipleTable.toggleRowSelection(obj);
              }
            });
          }
        });
      });
    },

    // 选择商品规格回调
    onChoiceGoods() {
      this.goodsForm = {};
      this.goodsDrawerVisible = true;
    },
    removeChoiceGoods(force) {
      this.removeDiscountInfo();
      this.form.goodsName = "";
      this.form.specName = "";
      this.goodsSpecTable = [];
      this.mealGroupData = [];
      this.specIdArr = [];
      this.collectionInfo.newGoodsPrice = 0;
      this.collectionInfo.goodsDiscountPrice = 0;
      this.oneItem = [];
      this.currentGoods = {};
      this.comComGoodsInfoList = []
      this.cjComComGoodsInfo = {}
      this.receiveItemData = []

      this.discountInfoList = [];
      this.discountNotuse = [];
      this.wxTradeOrderDiscountVOList=[];
      this.discountTip = "";
      this.discountPrice = "";
      this.form.jobLowestSalaryUnderCollege = ''
      this.form.jobLowestSalaryUpCollege = ''
      this.calcFinalMoney();
      this.repeat = false
      if(force){
        this.recoverTransactionInfo()
      }
    },

    removeDiscountInfo(){
      this.discountType = "";
      
      this.discountInfoList = [];
      this.discountNotuse = [];
      this.wxTradeOrderDiscountVOList=[];
      this.discountTip = "";
      this.discountPrice = "";
    },

    removeChoiceSpc() {
      this.form.specName = "";
      this.goodsSpecTable = [];
      this.mealGroupData = [];
      this.specIdArr = [];
      this.collectionInfo.newGoodsPrice = 0;
      this.collectionInfo.goodsDiscountPrice = 0;
      this.comComGoodsInfoList = []
      this.cjComComGoodsInfo = {}
      this.receiveItemData = []
      this.repeat = false
      this.calcFinalMoney();
    },

    // 展示商品规格弹窗
    onChoiceGoodsSpec() {
      if (this.form.goodsName.trim().length === 0) {
        return this.$message.error("请先选择商品");
      }
      if (this.form.specName === "默认班型") {
        return;
      }
      this.gsVisible = true;
    },

    // 选择商品弹窗确定
    async onGoodsDrawerOk(goods) {
      console.log('[onGoodsDrawerOk]', goods);
      this.removeChoiceGoods(); //移除当前数据
      // 商品定价状态 0 待定 1 已定
      this.statusPrice = goods.statusPrice
      
      //将所有的template初始化。即都不显示出来
      Object.keys(this.ctr).forEach((key) => {
        this.ctr[key] = false;
      });

      //根据不同的商品展示不同的template
      let getGoodsSpecs = "getGoodsSpecs";
       this.isCj = `${goods.businessId}` === '1';

      //表示是财经商品。
      if (goods.activeName === "first") {
        this.ctr.showCjSingleOption = true;
        this.isCj = true;
        //表示是自考商品，且是套餐。
      } else if (
        goods.activeName === "second" &&
        goods.xlType == 1 &&
        goods.type == 1
      ) {
        this.ctr.showZkMultipleOption = true;
        getGoodsSpecs = "getZkMultipleGoodsSpecs";
        this.xlType = goods.xlType;

        //表示是自考商品，且是单品。
      } else if (goods.activeName === "second" && goods.xlType == 1) {
        this.ctr.showZkSingleOption = true;
        getGoodsSpecs = "getZkGoodsSpecs";
        this.xlType = goods.xlType;

        //表示是网考、成交，且是单品。
      } else if (
        goods.activeName === "second" &&
        (goods.xlType == 2 || goods.xlType == 3)
      ) {
        this.ctr.showCkSingleOption = true;
        getGoodsSpecs = "getCkGoodsSpecs";
        this.xlType = goods.xlType;
      }

      if (this.currentGoods.goodsId == goods.goodsId && !this._priceChange) {
        this.goodsDrawerVisible = false;
        return;
      }

      this.$nextTick(() => {
        // 1单品单规格，2单品多规格，3单品默认规格，4套餐规格单选，5套餐规格多选，6套餐默认规格）
        this.currentGoods = goods;
        this.form.goodsName = goods.goodsName;
        this.currentGoodsRank = goods.goodsRank;
        this.goodsDrawerVisible = false;
        this.mealGroupData = [];
        this.isShowGoods = true;
        this[getGoodsSpecs]();
        // this.transferSignUpJudgeRecommend(goods)//更新转介绍
        this.clearValidate()
      });
    },


    // 用于判断选择班型后是否已经购买过了
    async getOrderStatus() {
      const tmpParams = this.createAfterSaleOrder();
      this.signUpForm.stuMoblie = this.form.stuMoblie
      console.log("参数", tmpParams);
      if(!tmpParams.userId){
        const hasUserId = await this.doLanJingData()
        if(hasUserId){
          tmpParams.userId = this.signUpForm.userId
        }
      }
      const params = {
        // userId: tmpParams.userId,
        // spPriceIdStr:
        //   tmpParams.createOrderVO.childSpecificationPriceIds ||
        //   tmpParams.createOrderVO.commodityIdList.toString(),
        userId: tmpParams.userId,
        commodityIdList: 
        // tmpParams.createOrderVO.childSpecificationPriceIds.split(',') ||
          tmpParams.createOrderVO.commodityIdList,
        businessIdInt: this.isCj?1:0,
        enrollingTime: ""
      };
      const { data, code, msg } = await this.$fetch(
        // "findOrderStatusByUserIdAndSpecificationId",
        "doCheckRepeatBuy",
        params
      );
      console.log("检查结果", data, code, msg);

      //purchaseStatusResultStatus购买状态(0 未购买(暂不返回数据) 1全款待支付 2已购买 3支付中 4分笔待支付)
      // if (data.purchaseStatusResultStatus === 0) {
      //   return false;
      // } else {
      //   this.$message.error("学员已报名该班型，请重新选择班型")
      //   this.removeChoiceSpc()
      //   return true;
      // }

      // 结果 status（1通过，0不通过）
      if(data.status == 0) {
        this.repeat = true
        // this.$message.error("学员已报名该班型，请重新选择班型");
        // return true
      } else {
        this.repeat = false
      }
    },

    // 获取商品规格接口
    async getGoodsSpecs() {
      // 重置商品规格弹窗数据
      this.goodsSpecModalKey = getRandomKey();
      this.form.specName = "";
      const params = {
        businessId: this.businessId,
        goodsId: this.currentGoods.goodsId,
      };
      this.collectionInfo.discountPrice = 0;
      this.collectionInfo.goodsDiscountPrice = 0;

      //获取商品列表信息
      let getGoodsSpecs = this.isCj
        ? "getGoodsSpecs"
        : "third_getfindSpByIdGoodsId";
      const { data } = await this.$fetch(getGoodsSpecs, params);
      this.specificationPriceArr = data.specificationPriceArr;

      if (data.specificationTypeArr.length > 0) {
        this.getGoodsSpBySpType();
        // 此字段是为了在 套餐多规格多选的情况下 限制选择的数量
        this.specTypeArr = data.specificationTypeArr;
        this.removeChoiceSpc();
      } else if (data.specificationTypeArr.length == 0) {
        //typeArr为空的话， priceArr就只有一条数据 这条数据就是默认班型
        this.specTypeArr = [];
        this.form.specName = "默认班型";
        this.collectionInfo.newGoodsPrice =
          data.specificationPriceArr[0].goodsPrice;
        this.specIdArr = [data.specificationPriceArr[0].id];
        this.goodsSpecTable = this.getSpcMergeData(
          data.specificationPriceArr.slice()
        );

        //如果是默认班型，那么就直接去获取优惠券信息
        let goodsId = this.currentGoods.goodsId
        let schoolId = this.transactionInfo.ncCampusSchoolId
        this.findActivityDetail( goodsId, schoolId, "", []);

        await this.getReceiveItem();
        await this.calcFinalMoney();
        await this.getOrderStatus();

        //对于默认规格(班型)的套餐，也要调用一下商品信息 0单品  1套餐
        if (this.currentGoods.type === goodTypeEnum.package) {
          await this.getSetMealGroupInfo();
          if(this.currentGoods.goodsTemplate == goodsTemplateEnum.cjzh){
            this.findComComGoodsInfo();
          }
        } else if (this.currentGoods.type === goodTypeEnum.single) {
          this.findGoodsCourseBySpPriceId();
        }
        
        if(this.isCj) {
          this.getEmploymentContract()
        }
      } else {
        //单规格
        this.specTypeArr = data.specificationTypeArr;
        console.log("选择单品规格1", data.specificationTypeArr);
        this.form.specName =
          data.specificationTypeArr[0].specificationNameArr[0].specificationName; //规格名字
        this.collectionInfo.newGoodsPrice =
          data.specificationPriceArr[0].goodsPrice;
        this.specIdArr = [data.specificationPriceArr[0].id];
        this.goodsSpecTable = this.getSpcMergeData(
          data.specificationPriceArr.slice(),
          true
        );
        await this.getReceiveItem();
        await this.calcFinalMoney();
        this.findGoodsCourseBySpPriceId();
        this.setSelcetionShow();
      }
      if (this.currentGoods && this.currentGoods.type === goodTypeEnum.package) {
        this.getSetMealGroupInfo();
      }
      this.specPriceArr = data.specificationPriceArr;
      this.specCountArr = data.specificationSelectCountArr || [];
    },

    // 获取商品规格第二行
    async getGoodsSpBySpType() {
      let params = {
        goodsId: this.currentGoods.goodsId,
      };
      const { data } = await this.$fetch("third_GoodsSpBySpType", params);
      this.specArray = data;

      //如果时价格变动引起重新获取规格，则自动填充
      if (this._priceChange) {
        this.autoSelSpecs();
      }
    },

    //价格改变，自动选择班型
    autoSelSpecs() {
      const specInfo = {
        // 计算出的总价
        totalPrice: 0,
        // 规格名称数组
        specificationName: "",
        // 规格id数组,
        specIdArr: [],
        // 整体的规格数组
        specArr: [],
      };
      let totalPrice;
      let specificationName;
      let specIdArr = [];
      let specArr = [];
      for (let i of this.specificationPriceArr) {
        if (this.previousSpecIdArr.findIndex((item) => item == i.id) >= 0) {
          specArr.push(i);
          specIdArr.push(i.id);
        }
      }

      totalPrice = this.getTotalPrice(specArr);
      specificationName = this.getSpecificationName(specArr);

      specInfo.specIdArr = specIdArr;
      specInfo.totalPrice = totalPrice;
      specInfo.specificationName = specificationName;
      specInfo.specArr = specArr;

      console.log("价格", totalPrice);
      console.log("生成的规格对象", specInfo);

      //模拟手动选择规格
      this.onSpecModalOk(specInfo);
    },

    //获得规格名
    getSpecificationName(val) {
      console.log("val", val);
      let curSpec = val[0];
      console.log("curSpec55555>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", curSpec);
      if (!curSpec) return "";
      console.log("规格名字2222", this.specTypeArr);

      let name = "";
      // 单品默认规格 ,套餐默认规格
      if (this.currentGoodsRank === 1 || this.currentGoodsRank === 3) {
        console.log("规格名字2222", this.specTypeArr);
        let len = this.specTypeArr.length;
        if (len === 0) {
          name = "默认规格";
        } else if (len === 1) {
          name = this.specTypeArr[0].specificationNameArr[0].specificationName;
        } else if (len == 2) {
          name = `${this.specTypeArr[0].specificationNameArr[0].specificationName}(${this.specTypeArr[1].specificationNameArr[0].specificationName})`;
        }
      }
      //单品多选，套餐单选
      else if (this.currentGoodsRank === 2 || this.currentGoodsRank === 4) {
        if (this.specTypeArr.length === 1) {
          name = curSpec.specificationName1;
        } else if (this.specTypeArr.length === 2) {
          name = `${curSpec.specificationName1}(${curSpec.specificationName2})`;
        }
      }
      // 套餐多规格多选的情况
      else if (this.currentGoodsRank === 5) {
        if (this.specTypeArr.length > 1) {
          let arr = [];
          val.forEach((item) => {
            arr.push(item.specificationName2);
          });
          let str = arr.length ? `(${arr.join("、")})` : "";
          return val.length ? val[0].specificationName1 + str : "";
        } else {
          //存在只有规格一项的情况
          let result = "";
          if (!val) return result;
          val.reduce(
            (pre, cur) => (result += cur.specificationName1 + ", "),
            result
          );
          return result.replace(/, $/, "");
        }
      }

      console.log("nn>>>>>>>", name);
      return name;
    },
    getTotalPrice(val) {
      let curSpec = val[0];
      if (!curSpec) return;
      if (this.currentGoodsRank === 1 || this.currentGoodsRank === 3) {
        if (val.length < 1) return;
        // 单品默认
        if (this.currentGoodsRank === 1) {
          return this.specPriceArr[0].goodsPrice;
          // 套餐默认
        } else if (this.currentGoodsRank === 3) {
          return this.specPriceArr[0].goodsDiscountPrice;
        }
      } else if (this.currentGoodsRank === 2 || this.currentGoodsRank === 4) {
        //单品多选，套餐单选
        if (this.currentGoodsRank === 2) {
          return curSpec.goodsPrice;
        } else if (this.currentGoodsRank === 4) {
          return curSpec.goodsDiscountPrice;
        }
      } else if (this.currentGoodsRank === 5) {
        let sum = 0;
        for (const item of val) {
          sum += item.goodsDiscountPrice;
        }
        return Number(sum).toFixed(2);
      }
    },
    // 老师输入的升班折扣输入框改变回调
    onDiscountPriceChange() {
      if (Number.isNaN(this.collectionInfo.discountPrice)) {
        return this.$message.error("只能输入数字");
      }
      if (
        this.collectionInfo.discountPrice > this.collectionInfo.newGoodsPrice
      ) {
        return this.$message.error("不能大于升班商品价格");
      }
      if (
        this.collectionInfo.discountPrice > this.collectionInfo.oldGoodsPrice
      ) {
        return this.$message.error("不能大于原商品价格");
      }
      this.calcFinalMoney();
    },
    // 计算最终价格
    calcFinalMoney() {
      //如果取消余额抵扣，本次使用归零
      if (this.collectionInfo.isBalancePay == 0) {
        this.collectionInfo.balancePay = 0;
      }

      let finalPrice;
      let goodsSpecTableFirstObj = this.goodsSpecTable[0] || { goodsPrice: 0 };

      if (this.collectionInfo.isBalancePay == "0") {
        // 不使用学员账户余额的场景
        // 应收款 = 升班商品售价 - 升班原商品抵扣价
        if (this.currentGoods.type === 0) {
          //单品
          finalPrice = accSub(
            goodsSpecTableFirstObj.goodsPrice,
            this.collectionInfo.discountPrice
          );
        } else {
          finalPrice = accSub(
            this.collectionInfo.goodsDiscountPrice || 0,
            this.collectionInfo.discountPrice
          );
        }
      } else {
        // 使用学员账户余额的场景
        // 应收款 = 升班商品售价 - 升班商品抵扣价 - 老师输入的余额抵扣价
        if (!this.collectionInfo.userMobile && this.form.stuMoblie) {
          this.getBalanceByMoblie(this.form.stuMoblie);
          this.collectionInfo.userMobile = this.form.stuMoblie;
        }
        let tmp;
        if (this.currentGoods.type === 0) {
          //单品
          tmp = accSub(
            goodsSpecTableFirstObj.goodsPrice,
            this.collectionInfo.discountPrice
          );
        } else {
          tmp = accSub(
            this.collectionInfo.goodsDiscountPrice || 0,
            this.collectionInfo.discountPrice
          );
        }
        const balancePay = isNaN(+this.collectionInfo.balancePay)
          ? 0
          : this.collectionInfo.balancePay;
        finalPrice = accSub(tmp, balancePay);
      }

      if (finalPrice < 0) {
        finalPrice = 0;
      }
      console.log("最终首付额度", finalPrice);
      this.collectionInfo.finalPrice = Number(finalPrice).toFixed(2);

      this.getReceiveItem(); //计算收费项目
    },
    // 取原订单详情
    goOrderDetail(row) {
      let path = `${ row.businessId}` === '1' ?  "/afterSale/cjOrderDetail": "/afterSale/xlOrderDetail";
      this.$router.push( {
        path: path,
        query: {
          orderId: row.orderId
        }
      });  
    },
    onDeleteRow(row) {
      console.log("删除已选订单信息", this.form, row);
      if (this.form.goodsName != "") {
        this.showDelOrder = true;
        this.tmpRow = row;
        return;
      }
      let deleteRow = this.orderTable.splice(
        this.orderTable.findIndex((item) => item === row),
        1
      );
      this.$refs.orderModal.deleteRow(deleteRow[0].orderId);
      if (this.orderTable.length == 0) this.setDeafultData();
      this.collectionInfo.oldGoodsPrice = 0
      this.getCalcDropout()
    },
    sureDelOrder() {
      //有商品时删除订单，弹出提醒
      this.form = {
        oneOrMore: this.form.oneOrMore,
        reason: "",
        phpneNumber: "",
        userName: "",
        idCard: "",
        // 学员真正的学习账号
        mobile: "",
        // 学员手机
        stuMobile: "",
        // 选择的商品名称
        goodsName: "",
        // 选择的商品规格名称
        specName: "",
      };
      this.orderTable.splice(
        this.orderTable.findIndex((item) => item === this.tmpRow),
        1
      );
      this.$refs.orderModal.clearSelection()
      this.setDeafultData();
      this.onOrderModalOk({orderId: this.orderId})
      this.showDelOrder = false;
    },
    //删除订单时 还原页面的数据，
    setDeafultData() {
      this.form.stuMoblie = '',
      this.form.name = ''
      this.form.idCard = ''
      this.signUpForm = {}; //清空学员信息
      this.orderExtraInfo = {}; //清空成交信息
      this.goodsSpecTable = []; //还原商品价格字段
      this.receiveItemData = []; //清空收费项目
      this.collectionInfo = {
        //清空收款信息
        // 选择的新升班商品售价
        newGoodsPrice: "0",
        // 原商品的售价->总已收金额
        oldGoodsPrice: "0",
        // 升班折扣 ，老师输入的
        discountPrice: 0,
        // 是否使用余额
        isBalancePay: "0",
        // 学员的账户上的余额
        stuAccountMoney: "0",
        // 余额抵扣的价格
        balancePay: 0,
        // 余额抵扣的账户
        userMobile: "",
        // 短信验证码
        phoneCode: "",
        //最后计算出的价格
        finalPrice: "0",
        isLoan: "0",
      };
      this.oneItem = [];
      this.currentGoods = {};
    },
    // 切换单选多选
    switchOneOrMore(oneOrMore) {
      if(oneOrMore === null) return
      if( (this.form.oneOrMore === '0' || oneOrMore === '0') && Array.isArray(this.orderTable) ){
        this.orderTable = this.orderTable.filter( item=> item.orderId === this.orderId )
        this.orderIds = this.orderTable.map((item) => item.orderId);
      }
      if(oneOrMore == 1){
        // eslint-disable-next-line
        const {idCard= '', mobile= '', signSchoolName = '', orderNo = ''} = this.orderTable[0] || {}
        if(mobile){
          this.orderModalFields.mobile = mobile
        }else if(idCard){
          this.orderModalFields.idCard = idCard
        }
      }else{
        this.orderModalFields.idCard = ''
        this.orderModalFields.mobile = ''
        this.orderModalFields.signSchoolName = ''
        this.orderModalFields.orderNo = ''
        this.$refs.orderModal.onReset()
        this.$refs.orderModal.clearSelection()
      }
      this.getCalcDropout()
    },
    //获得套餐所有已选中的课程
    getAllSeledClass() {
      if (
        this.$refs.selcetionClass &&
        this.$refs.selcetionClass.lessonStatus &&
        this.currentGoods.type === 1
      ) {
        alert("请保存选课");
        return false;
      }
      let allSel = [];
      for (let i of this.mealGroupData) {
        for (let j of i.goods) {
          if (j.checked === true) {
            allSel.push(j);
          }
        }
      }
      this.userSelectMealGroup = allSel;
      return true;
    },
    /**
     * 商品价格以及用户信息的验证
     */
    async  goodsPriceAndUserInfoValidate(){
      /***
       * 更新用户信息，或者创建用户信息
       */
      let errMsg = "";
      let hasUserId = await this.doLanJingData();
      if (!hasUserId) {
          errMsg = "用户信息更新蓝鲸失败，用户userId不存在，无法发起报名";
      }

      /***
       * 检查商品是否下架
       */
      if (await this.checkGoodsStatus() === 1) {
        errMsg = "商品已下架,请重新选择";
      }

      if( !this.transactionInfo.ncCustomerUserId ){
        errMsg = "成交信息中不存在ncId,不允许创建订单";
      }
    
      if( errMsg ){
        this.sureVisible = false;
        this.$message.error(errMsg)
      }

      return !errMsg;
    },
    // 检查商品是否下架
    async checkGoodsStatus() {
      const params = {
        goodsId: this.currentGoods.goodsId,
      };
      const { data, code } = await this.$fetch(
        "thrid_checkGoodsStatus",
        params
      );
      if (code === 200) {
        // goodsSchoolSellStatus  0待上架,1下架,2在售
        return data.goodsSchoolSellStatus;
      }
    },
    // 是否为存在就业协议
    async getEmploymentContract() {
      const res = await this.$fetch('employmentContract', {commodityIds: this.specIdArr.toString(), goodsId: this.currentGoods.goodsId})
      this.employmentShow = res.data
    },
    // 提交 生成售后单
    async onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if(!this.form.reason)  return this.$message.error("请填写申请原因");
        if (!valid) return this.$message.error("表单填写有误，请检查");
        let validatePass = await this.goodsPriceAndUserInfoValidate()
        if (!validatePass) return;
        const params = this.createAfterSaleOrder();

        // 升班不支持高升低
        const finalPrice = +this.collectionInfo.newGoodsPrice - +this.collectionInfo.oldGoodsPrice - +this.goodsDis - (isNaN(+this.discountPrice) ? 0 : +this.discountPrice)
        if(finalPrice < 0){
          return this.$message.error('升班不支持高升低，请重新选择班型')
        }
        console.log("[升班提交数据]", params);
        if(this.submitLoading) return console.log("[升班提交数据中]请稍等！！");
        this.submitLoading = true
        try {
          this.loading = true;
          params.channel = 1;
          // 添加全网userId
          params.createrQwId = this.loginUser.userId
          //20231110新增入参
          params.crmDeptId = this.loginUser.deptId
          
          const {code, data, msg} = await this.$fetch("third_submitAfterSaleOrder", params)
          .catch(res =>{
            this.submitLoading = false
            console.log("[升班错误]", res);
            res.msg && this.$message.error(res.msg);
            this.loading = false;
          })
          console.log("[升班返回]", {code, data, msg});
          this.submitLoading = false
          if (code === 200) {
            let orderId = ''
            let aftersaleId = ''
            if(Checker.isObject(data) ){
              orderId = data.orderId
              aftersaleId = data.aftersaleId
            }
            this.$message.success("成功创建一条升班售后单");
            let path = "/aftersale/list"
            let query = {orderId: orderId, property: this.property}
            if(finalPrice > 0){
              // 低升高
              path = '/aftersale/collection'
            }else if(finalPrice == 0){
              // 平升
              if(aftersaleId){
                path = '/afterSale/afterSaleDetail'
                query.id = aftersaleId
                query.type = aftersaleTypeEnum.upgrade
              }
              // 需要签约
              if(this.currentGoods.contractSign == 1){
                path = '/aftersale/payComplete'
              }
            }
            this.$router.replace({
              path,
              query
            });
            this.$store.commit("setUpdateOrderList", true);
          } else {
            this.$message.error(msg);
          }
          this.loading = false;
        } catch (err) {
          this.submitLoading = false
          this.loading = false;
          console.error('[升班错误]catch: ', err)
        }
        this.sureVisible = false;
      });
    },
    // 保存 保存对应选择的ids数组
    async onSave() {
      const params = this.createAfterSaleOrder();
      params.channel = 1;
      const { code, msg } = await this.$fetch(
        "third_saveAfterSaleOrder",
        params
      );
      if (code === 200) {
        this.$router.push("/aftersalle/list");
        this.$store.commit("setUpdateOrderList", true);
      } else {
        this.$message.error(msg);
      }
    },
    // 创建售后单
    /**
     *
     *   0: "退费",
        1: "升班",
        2: "转班",
     *
     * */
    createAfterSaleOrder() {
      // 订单数组
      const aftersaleOrder = this.orderTable.map((item) => ({
        orderId: item.orderId,
        orderNo: item.orderNo,
        goodsId: item.goodsId,
        goodsName: item.goodsName,
        goodsSpecId: item.goodsSpecId,
        goodsSpecName: item.goodsSpecName,
        productId: item.productId,
        goodsTypeId: item.goodsTypeId,
        isMain: this.orderTable[0].orderId === item.orderId ? 1 : 0, //主单标记为1，从单为0
        subscribeSchoolId: item.subscribeSchoolId,
        subscribeSchoolName: item.subscribeSchoolName
      }));
      // 收款信息数组
      let oldGoodsPrice = isNaN(+this.collectionInfo.oldGoodsPrice) ? this.collectionInfo.oldGoodsPrice : +this.collectionInfo.oldGoodsPrice
      const aftersaleDetail = {
        newGoodsPrice: this.collectionInfo.newGoodsPrice,
        oldGoodsPrice: oldGoodsPrice,
        discountPrice: oldGoodsPrice,
        isBalancePay: this.collectionInfo.isBalancePay,
        balancePay: Number(this.collectionInfo.balancePay),
        userMobile: this.collectionInfo.userMobile,
        finalPrice: isNaN(+this.shouldPay) ? 0 : +this.shouldPay,
        code: this.collectionInfo.phoneCode,
        isLoan: this.collectionInfo.isLoan,
      };
      // 选择的套餐课程
      let userSelectMealGroupIds =
        this.userSelectMealGroup.map(
          (item) => item.goodsSpecificationPriceId
        ) || [];
      console.log("orderExtraInfo", this.orderExtraInfo);
      if( this.wxTradeOrderDiscountVOList.length  ){
          if( !this.discountPrice ){
            this.discountPrice = 0;
          }
          this.wxTradeOrderDiscountVOList[0].discount = this.discountPrice
          const specificationNameIdInfo = this.getSpecificationNameIdInfo()
          this.wxTradeOrderDiscountVOList.map((item, index) =>{
            this.wxTradeOrderDiscountVOList[index].goodsId = this.currentGoods.goodsId
            this.wxTradeOrderDiscountVOList[index].schoolId = this.transactionInfo.ncCampusSchoolId//-1全国校区
            this.wxTradeOrderDiscountVOList[index].specificationNameId1 = specificationNameIdInfo.specificationNameId1
            this.wxTradeOrderDiscountVOList[index].specificationNameIds2 = specificationNameIdInfo.specificationNameIds2
          })
      }
       
      const params = {
        wxTradeOrderDiscountVOList: this.wxTradeOrderDiscountVOList,
        userId: this.signUpForm.userId,
        aftersaleType: aftersaleTypeEnum.upgrade,
        // 升班类型（0：一升一，1：多升一）
        changeClassType: this.form.oneOrMore,
        reason: this.form.reason,
        idCard: this.form.idCard || "",
        mobile: this.form.stuMoblie,

        createrId:  this.getTyUser().id,//this.userInfo.id,
        creater: this.getTyUser().nickName || this.getTyUser().userName,//this.userInfo.userName,

        schoolId: this.orderTable[0].signSchoolId || '',
        schoolName: this.orderTable[0].signSchoolName || '',
        signSchoolName:this.orderTable[0].signSchoolName || '',
        aftersaleSchoolNcid: this.orderTable[0].signSchoolId || '',//售后校区

        aftersaleOrder,
        aftersaleDetail,
        balanceAccount: this.form.mobile,
        businessId: this.currentGoods.businessId, //赛道
        promotionForm: this.transactionInfo.promotionForm,//促单形式
        isFourSix: ~~this.transactionInfo.isFourSix,//46分成
        // 创建订单对象（详情参考订单文档生成订单接口）
        createOrderVO: {
          phoneNumber: this.form.stuMoblie || "", //学员账号
          idCard: this.form.idCard || "", //身份证号
          name: this.form.name || "", //学员姓名
          education: this.form.education, // 学员学历

          goodsTemplate: this.currentGoods.goodsTemplate, //判断财经商品是否是组合套餐
          goodsId: this.currentGoods.goodsId,
          userId: this.signUpForm.userId,
          commodityIdList: this.specIdArr,
          childSpecificationPriceIds: userSelectMealGroupIds.toString(),
          isFree: 1,
          schoolId: 4,
          platformType: 1,
          isSendMessage: 0,
          signSchoolName: this.orderExtraInfo.signSchoolName,
          signSchoolId: this.orderExtraInfo.assignedDeptCode,
          orderType: 2,
          oldOrderId: this.orderIds.toString(),
          isFormal: 1,
          isReceiveAll: 0,
          isIos: 0,
          businessIdInt: 1,
          schoolName: this.orderExtraInfo.signSchoolName,
          wxTradeOrderExtra: {
            projectCode: this.orderExtraInfo.projectCode, //赛道
            isRecommand: this.teachOrder.qkcStatus == 0 ? "否" : "是",
            gztDeptId: this.teachOrder.gztDeptId,
            teacherRecruitName: this.orderExtraInfo.teacherName || "", //招生老师名称
            teacherRecruitId: this.teachOrder.teacherId || "", //招生老师Id
            teacherRecruitAffiliationName:
              this.teachOrder.teacherDeptName || "", //招生老师部门名称 全网新增的，可能为空
            teacherRecruitAffiliationId: this.teachOrder.schoolId || "", //招生老师部门Id
            subscribeSchoolName: this.orderExtraInfo.signSchoolName || "", //成交校区
            subscribeSchoolId: this.orderExtraInfo.assignedDeptCode || "", //成交校区id

            teacherRecommandId: this.teachOrder.qkcTeacherId || "", //转介绍老师id
            teacherRecommandName: this.teachOrder.qkcTeacherName || "", //转介绍老师名字
            teacherSalesId: this.teachOrder.assignedUserId || "",
            teacherSalesName: this.teachOrder.assignedUserName || "", //销售老师
            teacherSalesAffiliationName:
              this.teachOrder.assignedUserDeptName || "", //销售老师部门名称
            teacherSalesAffiliationId: this.teachOrder.assignedUserDeptId || "",
            affiliationId: this.teachOrder.assignedDeptId || "", //组织机构id
            affiliationName: this.teachOrder.assignedDeptName || "",

            teacherSalesNCid: this.teachOrder.assignedUserCode || "", //销售老师NCID
            teacherRecruitNCid: this.teachOrder.teacherCode || "", //招生老师NCID
            teacherRecommandNCid: this.teachOrder.teacherNcId || "", //转介绍老师NCID
            ...{...this.transactionInfo, ...{isFourSix: ~~this.transactionInfo.isFourSix}},
            customerCode: this.signUpForm.customerCode || "", //客户编号
            name: this.form.name || "", //学员姓名
            idCard: this.form.idCard || "", //身份证号
            learnPhoneNumber: this.form.stuMoblie || "", //学员手机(全网) 从成交信息里取
            phoneNumber: this.form.stuMoblie || "", //学习账号（蓝鲸）
          },
          jobLowestSalaryUnderCollege: this.form.jobLowestSalaryUnderCollege,
          jobLowestSalaryUpCollege: this.form.jobLowestSalaryUpCollege
        },
        isRecommand: this.transactionInfo.isRecommand,
        transIntroduceType: this.transactionInfo.transIntroduceType,
      };
      if (this._draft && this._draft.id) {
        params.id = this._draft.id;
      }
      return params;
    },
    onOrderModalCancel() {
      this.orderModalVisible = false;
    },
    // 订单弹窗确定回调
    async onOrderModalOk(rows) {
      //1、查看当前的orderId，是否在已选中的里面，如果没有则添加都首行
      if( !Array.isArray(rows)){
        rows = [rows]
      }
      if(!rows.length || !rows[0].orderId) return
      this.setPageDeafultData();
      rows = rows.filter( item=>{
        return item.orderId !== this.orderId;
      })
      rows.push({
        orderId: this.orderId
      }) 
      let ids = rows.map((item) => item.orderId);
      this.orderIds = ids;
      if(!this.compareOrderTable(rows)){
        this.removeChoiceGoods(true)
      }
      await this.getDetailData(ids);
      this.orderModalVisible = false;
    },
    // 两次的订单不一致，直接清除当前商品
    compareOrderTable(rows){
      let orderTable = this.orderTable.slice(0)
      if(rows.length === 1 && orderTable.length === 1 && rows[0].orderId === orderTable[0].orderId){
        return true
      }
      if(rows.length !== orderTable.length){
        return false
      }
      let count = 0
      rows.map(rt =>{
        orderTable.map(item =>{
          if(rt.orderId === item.orderId){
            count++
          }
        })
      })
      return count === rows.length
    },

    //选订单时，重置页面一些数据
    setPageDeafultData() {
      this.collectionInfo.oldGoodsPrice = 0; //重置升班抵扣额
      this.collectionInfo.balancePay = "";
    },

    // 获取订单表格
    async getDetailData(ids) {
      ids = ids.toString();
      console.log(ids);
      const params = {
        ids,
      };
      const { data } = await this.$fetch("third_choiceOrderDetailById", params);
      this.orderTable = data.orderLists || []; //售后报名单的列表
      //找到第一条, 第一条是从详情页带过来的
      this.firstOrderList = this.orderTable.filter( item=>item.orderId === this.orderId );
      this.otherOrderList = this.orderTable.filter( item=>item.orderId !== this.orderId );

      if(this.form.oneOrMore == 1){
        // 多升一
        // 检查主订单与其他订单是不是属于同一校区
        const upClassDealSchoolNcId =[]
        this.otherOrderList.map(item => upClassDealSchoolNcId.push(item.subscribeSchoolId))
        console.log('[多升一订单检查]', ' originalDealSchoolNcId:', this.firstOrderList[0].subscribeSchoolId, ' upClassDealSchoolNcId:', upClassDealSchoolNcId );
        if(upClassDealSchoolNcId.length){
          const checkRes = await doPromotionCheck({
            originalDealSchoolNcId: this.firstOrderList[0].subscribeSchoolId,
            upClassDealSchoolNcId
          })
          if(checkRes.code !== 0){
            // 校验不通过
            checkRes.msg && this.$message.warning(checkRes.msg);
            this.otherOrderList = []//去除其他订单
            // 重置订单选择弹窗
            this.$refs.orderModal.setDeafultData();
          }
        }
        // 获取当前赛道下所有产品线
      }

      this.orderTable = [...this.firstOrderList, ...this.otherOrderList]

      this.orderExtraInfo = data.orderExtraInfo[0] || {};
      //正常升班流程
      if (data.orderLists.length) {
        //获取客户ID
        //有客户id，则去请求全网的成交信息
        // 有客户id的话，取全网接口的信息来替换orderExtraInfo (学员信息)
        //获取蓝鲸学员的信息
        this.setUserFirstData();
      }
    
      this.getCalcDropout(ids); //获取升班抵扣 原付费总数
    },

    distinct(a) {
      return Array.from(new Set([...a]));
    },
    //取第一条订单的数据填充学员信息
    setUserFirstData() {

      console.log("取第一条订单的数据填充学员信息", this.orderTable);
      let data = this.orderTable[0];
      this.signUpForm = JSON.parse(JSON.stringify(data));

      // 获取学员信息 禅道7238
      getSelectStudentInfo(this.orderId, 0).then(async res => {
        let { code, result } = res
        if(code === 0) {
          this.form.idCard = result.idCard
          this.form.stuMobile = result.phoneNumber
          this.form.name = result.name
          this.form.stuMoblie = result.phoneNumber
          this.form.mobile = result.phoneNumber
          this.form.education = result.education

          if(!!result.phoneNumber) this.isCanStudy = true

          // if(!!result.phoneNumber && !result.userId) {
          //   // 学习账号不为空，学员id为空。根据学习账号获取学员信息
          //   let list = await getSelectLjUserInfo({ mobile: result.phoneNumber })
          //   if(list.code === 0) {
          //     // 能获取到信息就直接用，不能获取到信息就新增
          //     if(list.result.userId) {
          //         this.form.stuMoblie = list.result.mobile
          //         this.form.userId = list.result.userId
          //     } else {
          //       let obj = {
          //         idCard: this.signUpForm.idCard,
          //         mobile: this.signUpForm.stuMoblie,
          //         realName: this.signUpForm.name
          //       }
          //       let re = await doSaveLjUser(obj)
          //       if(re.code === 0) {
          //           this.form.stuMoblie = re.result.mobile
          //           this.form.userId = re.result.userId
          //       }
          //     }
          //   }
          // }
          // if(!result.phoneNumber && !!result.userId) {
          //   // 学习账号为空，学员id不为空
          //   let list = await getSelectLjUserInfo( { userId: result.userId })
          //   if(list.code === 0) {
          //       this.form.stuMoblie = list.result.mobile
          //       this.form.userId = list.result.userId
          //   }
          // }
        }
      })
      // this.form.idCard = data.idCard
      // this.form.stuMobile = data.stuMobile
      // this.form.name = data.stuName;
      this.signUpForm.name = data.stuName
      this._tmpMoile = data.mobile; //用于检测手机号是否改变了
      // this.form.mobile = data.mobile;
      // this.form.stuMoblie = data.mobile;
      this.signUpForm.stuMoblie = data.mobile


      this.signUpForm.customerCode = data.customerCode;
      this.signUpForm.customerCodeList = this.distinct(
        this.orderTable.map((it) => it.customerCode)
      ); //多升一，学员信息的客户编号列表
      this.signUpForm.mobileList = this.distinct(
        this.orderTable.map((it) => it.mobile)
      ); //多升一，账号列表
      this.signUpForm.stuNameReadonly =
        this.signUpForm.stuName && this.signUpForm.stuName.length > 0
          ? true
          : false;
      this.signUpForm.idCardonly =
        data.idCard && data.idCard.length > 0 ? true : false;
      this.hasStuInfo = true;
      // 学习账号关联商机手机号，如手机号已注册，引用商机手机号且不允许修改
      this.disabledChangeStuMobile = !!(data.mobile && data.userId)

    },
    // 新增学员到蓝鲸
    addCustomeruser() {
      //判断是不是手机号，并且返回。
      if (!this.isPhoneNumber(this.signUpForm.stuMoblie)) {
        this.signUpForm.stuMoblie = ''
        return this.$message.warning("学员账号格式不正确");
      }

      doSaveLjUser({
        idCard: this.signUpForm.idCard,
        mobile: this.signUpForm.stuMoblie,
        realName: this.signUpForm.name
      }).then(res => {
        let { code, result } = res
        if(code === 0) {
          this.isCanStudy = true
          Object.assign( this.signUpForm, {
            stuMoblie: result.mobile,
            userId:    result.userId
          });
        }
      })
    },
    // 自动通过学员账号查询学员信息（废弃）
    async getCustomeruser(isNotNotifce) {
      let {stuMoblie, name, idCard } = this.form;

      //判断手机号是不是没有改变
      if( stuMoblie == this._tmpMoile ){
        return;
      }

      //判断是不是手机号，并且返回。
      if (!this.isPhoneNumber(stuMoblie)) {
        return !isNotNotifce && this.$message.warning("学员账号不正确");
      }

      //根据用户手机号获取用户信息
      let { data={}, code } = await this.$fetch("getUserByMobile", {  mobile: stuMoblie });
      
      //检查学生信息，有就更新，没有就新增
      // this._tmpMoile = this.signUpForm.stuMoblie;
      this._tmpMoile = this.form.stuMoblie;
      // debugger
      if( data == null || code != 200 ){
        this.signUpForm.userId = null;
        return;
      }

      //(1) 如果当前不存在学员姓名，以及idcard,那么直接更新;
      //(1) 如果存在姓名和身份证号，就询问下是否需要覆盖之前信息
      if( name && idCard ){
        
          this.$hqMessageBox({
            message: '是否替换当前学员信息?'
          }).then( ()=>{
            //确定更新
            this.updateUserInfo(data);
          })
      }else {
        this.updateUserInfo(data);
      }
    },
    updateUserInfo(data){
      Object.assign( this.signUpForm, {
        userId: data.userId
      });
      Object.assign( this.form, {
        name:      data.realName,
        idCard:    data.idCard,
        stuMoblie: data.mobile,
      });
    },

    // 获取退费计算表格数据
    async getCalcDropout() {
      //升班抵扣合计
      let amountReceived = 0
      for (let i of this.orderTable) {
        amountReceived += isNaN(+i.amountReceived) ? 0 : +i.amountReceived
      }
      this.collectionInfo.oldGoodsPrice = accAdd(amountReceived, 0, 2)
      this.collectionInfo.discountPrice = this.collectionInfo.oldGoodsPrice;
      this.calcFinalMoney();
    },
    // 编辑当前行
    onDropOutCellClick(row) {
      row.edit = true;
    },
    // 更改核定可退款金额
    editDeductionMoney(row) {
      // 不合法 过滤
      if (Number.isNaN(+row.deductionMoney)) {
        return this.$message.error("请输入合法的金额");
      }
      // 超过已收金额 过滤
      if (row.deductionMoney > row.totalMoney) {
        return this.$message.error("最多不超过已收金额");
      }
      // 小于0过滤
      if (row.deductionMoney < 0) {
        return this.$message.error("不能小于0");
      }
      // 保留两位小数
      row.deductionMoney = Number(row.deductionMoney).toFixed(2);
      row.edit = false;
    },

    // 根据手机号获取学员的账户余额
    async getBalanceByMoblie(mobile) {
      if (!mobile) {
        return this.$message.error("请输入手机账号");
      }
      const params = { mobile };
      const { data, code } = await this.$fetch(
        "thrid_getBalanceByMoblie",
        params
      );
      console.log("codd", code);
      if (code === 407) {
        return this.$message.error("手机号不存在");
      }
      if (!data) return false;
      this.collectionInfo.stuAccountMoney = data.money;
      this.collectionInfo.userName = data.nickName;
    },
    // 关闭商品规格弹窗
    onGsCancel() {
      this.gsVisible = false;
    },
    setSelUserShow() {
      this.selUserVisible = true;
    },
    //预览商品
    async previewGoods(goodsId) {
      console.log("商品id", goodsId);
      const params = {
        goodsId: goodsId,
      };
      const { data } = await this.$fetch("previewGoods", params);
      window.open(data.url, "_blank");
    },
    //获得单品的信息
    async findGoodsCourseBySpPriceId() {
      const params = {
        goodsId: this.currentGoods.goodsId,
        spPriceId: this.specIdArr.toString(),
      };
      const { data } = await this.$fetch(
        "findGoodsCourseBySpPriceId",
        params
      );
      console.log("单品的课程信息", data);
      this.oneItem = data;
    },
  /***
   * 获取成交信息
   */
  getTransactionInfo(){
    let form = {
      orderId: this.orderId,
      userId: this.loginUser.userId,
      transferSchool: this.$route.query.transferSchool == 1?true:false,
      type: 2,
    }
         
    getTransferDetail(form).then( res=>{

      if( res.code === 0 ){
        this.transactionInfo = res.result || {};
        // this.form.education = res.result.education
        console.log('this.transactionInfo', this.transactionInfo);
        Object.keys(this.transactionInfo).forEach( key=>{
            if( key == "isRecommand" ){
              this.transactionInfo[`${key}Name`] = this.transactionInfo[key] == 1 ? '是': '否'
            }
        })
        this.cacheOriginTransactionInfo(this.transactionInfo)
        this._getDeptorderId(this.transactionInfo.crmDealSchoolDeptId)
        // if(res.result.customerId) {
        //   getCustomerData(this.transactionInfo.customerId).then(res => {
        //     res.result.contactList.map(item => {
        //       if(item.type == 0) {
        //         this.phoneList.push(item.detail)
        //       }
        //     })

        //     if(this.phoneList.indexOf(this.signUpForm.stuMoblie) == -1) {
        //       this.form.stuMoblie = this.phoneList[0]
        //       this.signUpForm.stuMoblie = this.phoneList[0]
        //     }
        //   })
        // }
      }else{
        this.$message.error("转校获取成交信息错误:"+res.msg);
        console.log("转校获取成交信息错误:"+res.msg)
      }
    })
  },
  // 通过部门拿成交信息
  _getDeptorderId(val) {
    getDeptId(val).then(res => {
      console.log('通过部门拿到成交信息',res);
      this.property = res.result.property
    })
  },
  //待收金额 商品-优惠额-抵扣额
  waitPay() {
    let price = 0;
    if( !this.collectionInfo.balancePay){
      this.collectionInfo.balancePay = 0
    }
    price = this.shouldPay - this.collectionInfo.balancePay;
    if( price < 0  || isNaN(price) ){
      price = 0;
    }
    
    this.waitPayPrice = price.toFixed(2) || "--";
  },
  //应退金额
  shouldReturnPay(){
    let price = 0;

    //如果应收款 > 0， 那么应退款就是是被全部抵扣完了，所以应退款为0；
    if( this.waiPay > 0.000001 ){
      return 0;
    }

    //如果应收款为0，那么就存在应退金额
    if (this.isCj) {
      price =
        this.newGoodsPrice -
        this.goodsDiscount -
        this.collectionInfo.balancePay - this.approvedRefundableAmount;
    } else {
      price =
        this.zkNewGoodsPrice -
        this.zkGoodsDiscount -
        this.collectionInfo.balancePay - this.approvedRefundableAmount;
    }

    if( price > 0 ){
      price = 0;
    }else {
      price = -price;
    }
    this.shouldReturnPayPrice =  price.toFixed(2) || "--";
  },
  // 余额抵扣设置更新回调
  updateBanlancePayOption(){
    this.$nextTick(()=>{
      console.log('[updateBanlancePayOption]', this.collectionInfo.isBalancePay)
      this.calcFinalMoney()
    })
  },
  /***
   * 获取组合套餐信息
   * 参数: spPriceIdStr
   */
  async findComComGoodsInfo() {
    if(!this.specIdArr || !this.specIdArr.length) return
    const params = {
      spPriceIdStr: this.specIdArr.toString(),
    };
    const { data } = await this.$fetch("third_findComComGoodsInfo", params);
    this.cjComComGoodsInfo = data || {};

    let singleGoodsSpPriceInfoList =
      this.cjComComGoodsInfo.singleGoodsSpPriceInfo || [];
    this.comComGoodsInfoList = [];
    for (let singleGoodsSpPriceInfoItem of singleGoodsSpPriceInfoList) {
      if (
        singleGoodsSpPriceInfoItem.kuaidaTotal === 0 ||
        singleGoodsSpPriceInfoItem.kuaidaTotal === "0"
      ) {
        singleGoodsSpPriceInfoItem.kuaidaTotal = "无上限";
      }
      if (singleGoodsSpPriceInfoItem.kuaidaTotal == null) {
        singleGoodsSpPriceInfoItem.kuaidaTotal = "--";
      }

      if (
        !(
          singleGoodsSpPriceInfoItem.spPriceName == "默认" ||
          singleGoodsSpPriceInfoItem.spPriceName == "默认班型" ||
          singleGoodsSpPriceInfoItem.spPriceName == "默认规格"
        )
      ) {
        singleGoodsSpPriceInfoItem.goodsName =
          singleGoodsSpPriceInfoItem.goodsName +
          "-" +
          singleGoodsSpPriceInfoItem.spPriceName;
      }
      this.comComGoodsInfoList.push({
        ...singleGoodsSpPriceInfoItem,
      });
    }
  },
  // 点击返回按钮
  doBack(){
    this.$hqMessageBox({
      title: "取消升班确认",
      message: '返回将不保留当前升班信息，是否确定取消升班？',
    })
    .then(() => {
      this.$router.back()
    })
    // eslint-disable-next-line
    .catch(action => {})
  },
  // 身份证号码检验
  idcardBlur() {
    this.$refs.form.validateField('idCard')
  }
},
  mounted() {

    this.orderId = this.$route.query.orderId;
    this.afterSaleId = this.$route.query.aftersaleId;
    //如果 this.afterSaleId 存在，那么会走 initData 来获取order数据。
    if( !this.afterSaleId ){
      this.onOrderModalOk({orderId: this.orderId});
    }

    this.$once("beforeDestroy", () => {
      clearInterval(this.smsCodeTimer);
    });
    this.form.stuMoblie = this.$route.query.stuMoblie || "";
    // this.getCustomeruser( true );
    this.getTransactionInfo();
  },
  computed: {

    ...mapGetters(["getTyUserInfo", "loginUser"]),

    projectList() {
      return this.$store.state.workbench.projectList;
    },
    // 售后单类型
    afterSaleType() {
      return this.$route.query.type;
    },
    labelTitle() {
      return "包含课程";
    },
    upgradeIds() {
      return this.$store.state.order.upgradeIds;
    },
    transferIds() {
      return this.$store.state.order.transferIds;
    },
    orderBtnDisabled() {
      if (
        this.form.oneOrMore == "1" &&
        this.form.idCard &&
        this.form.idCard.trim().length === 0
      ) {
        return true;
      }

      if (
        this.form.oneOrMore == "1" &&
        !this.form.idCard &&
        this.form.mobile.trim().length === 0
      ) {
        return true;
      }
      return false;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },

    //商品优惠,即商品优惠了多少元
    goodsDis() {
      if (
        this.goodsSpecTable[0] &&
        this.goodsSpecTable[0].goodsPrice > 0 &&
        this.goodsSpecTable[0].goodsDiscountPrice > 0
      ) {
        return accSub(
          this.goodsSpecTable[0].goodsPrice,
          this.goodsSpecTable[0].goodsDiscountPrice
        );
      } else {
        return 0;
      }
    },
    // 能否使用余额抵扣
    canUseBalancePay() {
      let allPay =
        this.goodsSpecTable[0] && this.goodsSpecTable[0].goodsPrice > 0
          ? this.goodsSpecTable[0].goodsPrice
          : 0; //商品总价
      let shouldPay = accSub(allPay, this.goodsDis) || 0; //商品套餐有折扣后价格，或单品原价，即现在售价
      if (accSub(shouldPay, this.collectionInfo.oldGoodsPrice) > 0) {
        return false; //开放余额抵扣按钮
      } else {
        return true;
      }
    },

    //当前商品的售价
    nowGoodsPrice() {
      let allPay =
        this.goodsSpecTable[0] && this.goodsSpecTable[0].goodsPrice > 0
          ? this.goodsSpecTable[0].goodsPrice
          : 0; //商品总价
      let shouldPay = accSub(allPay, this.goodsDis) || 0; //商品套餐有折扣后价格，或单品原价，即现在售价
      return shouldPay > 0 ? shouldPay : 0;
    },

    //应收金额  商品-优惠额
    shouldPay() {

      let price = 0;
      if (this.isCj) {
          price = this.nowGoodsPrice - this.discountPrice - this.collectionInfo.oldGoodsPrice;
      } else {
        price = this.zkNewGoodsPrice - this.discountPrice-this.collectionInfo.oldGoodsPrice;
      }
      if( isNaN(price) || price < 0.0001 ){
        price = 0
      }
      return price.toFixed(2) || "--";
    },
    // 是否设置关闭了余额抵扣
    disabledBalancePay(){
      return this.collectionInfo.isBalancePay != 1
    },
    loginUserSchoolId(){
      return (this.$store.getters.loginUser.deptId || '') + ''
    },
    ncDealSchoolId(){
      return this.transactionInfo.ncCampusSchoolId || ''
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
